import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
} from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthenticatedUser } from '../../contexts/auth';
import { RoleName } from '../../models/users';

interface SidebarItemProps {
  icon: React.ReactNode;
  title: string;
  to?: string;
  type?: 'group' | 'link' | 'sub';
  role?: RoleName[];
  children?: React.ReactNode;
  open?: boolean;
  onToggle?: () => void;
}

const SidebarItem: React.FC<SidebarItemProps> = props => {
  const { icon, title, to, role, type, children, open, onToggle } = props;
  const location = useLocation();
  const active = !!to && location.pathname.startsWith(to);
  const { hasRole } = useAuthenticatedUser();
  if (role && !role.some(r => hasRole(r))) return null;

  // const sx: SxProps<Theme> = {
  //   borderRadius: '10px',
  //   padding: '4px 16px',
  // }; //TODO

  const background = (theme: Theme) =>
    active ? theme.palette.blockBackground.gray : 'transparent';

  const commonStyles = {
    borderRadius: '10px',
    padding: '4px 16px',
    marginLeft: type === 'sub' ? '15px' : '0px',
  };

  const iconStyles = {
    color: 'black',
    minWidth: '25px',
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
    },
  };

  const textStyles = {
    '& .MuiTypography-root': {
      fontSize: type === 'group' ? '11px' : '11px',
      letterSpacing: '1px',
    },
  };

  if (type === 'group') {
    return (
      <Box sx={{ background, ...commonStyles, padding: '0px' }}>
        <ListItemButton onClick={onToggle} sx={{ borderRadius: 2 }}>
          <ListItemIcon sx={iconStyles}>{icon}</ListItemIcon>
          <ListItemText primary={title} sx={textStyles} />
          {children && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        {children && (
          <Collapse in={open} timeout='auto' unmountOnExit>
            {children}
          </Collapse>
        )}
      </Box>
    );
  }

  return (
    <ListItemButton
      {...(!!to ? { component: Link, to } : {})}
      sx={{ background, ...commonStyles }}
    >
      <ListItemIcon sx={iconStyles}>{icon}</ListItemIcon>
      <ListItemText primary={title} sx={textStyles} />
    </ListItemButton>
  );
};

export default SidebarItem;
