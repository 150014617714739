import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import nopicture_m from '../../assets/nopicture-m.png';
import { useAuthenticatedUser } from '../../contexts/auth';
import { ICreativeData, PresenceItem } from '../../models/creativeData';
import { getColor } from '../../theme/colors';
import dates from '../../utils/dates';
import { AvatarWithTooltip } from '../Avatar';
import PresenceDialog from '../PresenceDialog';

const avatarSize = 40;
const avatarMr = 10;
const avatarTotal = avatarSize + avatarMr;
const markersTargetDistance = 3;

interface PresenceTimelineProps {
  data: PresenceItem[];
  metadata?: ICreativeData['general'];

  controls?: {
    setSeekTime?: (time: number) => void;
  };
}

const PresenceTimeline: React.FC<PresenceTimelineProps> = ({
  data,
  metadata,
  controls,
}) => {
  const { api } = useAuthenticatedUser();
  const [presences, setPresences] = useState<PresenceItem[]>(data);
  const [selectedItem, setSelectedItem] = useState<PresenceItem | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});
  useEffect(() => {
    setPresences(data);
  }, [data]);

  useEffect(() => {
    const getSignedUrls = async () => {
      const urlMatcher = /^https?:\/\/[^\s/$.?#].[^\s]*$/i;

      const buffRecord: Record<string, string> = {};

      // Create an array of promises for processing each image
      const imageChecks = presences.map(async p => {
        if (!p.image) return;

        try {
          const signedUrl = urlMatcher.test(p.image)
            ? p.image
            : await api.url.getSignedUrl(p.image);
          const img = new Image();
          img.src = signedUrl;
          await img.decode();
          buffRecord[p.label] = signedUrl;
        } catch (error) {
          buffRecord[p.label] = nopicture_m;
        }
      });
      // Wait for all images to be checked before updating state
      await Promise.all(imageChecks);
      setImageUrls(buffRecord);
    };

    getSignedUrls();
  }, [presences, api.url]);

  const totalDuration =
    metadata?.videoDuration ||
    presences.reduce((acc, item) => {
      if (Array.isArray(item.appearances) && item.appearances.length > 0) {
        const maxEndTime = Math.max(
          ...item.appearances.map(period =>
            dates.parseTimestamp(period.timeRange.end),
          ),
        );
        return Math.max(acc, maxEndTime);
      }
      return acc;
    }, 0);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return [hours, minutes, remainingSeconds]
      .map(unit => unit.toString().padStart(2, '0'))
      .join(':');
  };

  const markersCount = totalDuration
    ? Math.min(Math.floor(totalDuration / markersTargetDistance) + 1, 5)
    : 0;
  const timeMarkers = Array.from({ length: markersCount }, (_, i) =>
    Math.round((i * totalDuration) / (markersCount - 1)),
  );

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem(null);
  };

  const handlePersonClick = (person: PresenceItem) => {
    // Only set new person and open dialog if it's not already open
    if (!dialogOpen) {
      setSelectedItem(person);
      setDialogOpen(true);
    }
  };

  return (
    <ChartContainer>
      <Chart>
        {presences.map((item, i) => (
          <ItemRow key={i}>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => handlePersonClick(item)}
            >
              <AvatarWithTooltip
                src={imageUrls[item.label]}
                alt={item.label as string}
                width={avatarSize}
                height={avatarSize}
                marginRight={`${avatarMr}px`}
              />
            </Box>
            <Timeline>
              {Array.isArray(item.appearances) &&
                item.appearances.map((period, j) => (
                  <Tooltip
                    key={j}
                    title={`${dates.parseTimestamp(
                      period.timeRange.start,
                    )} - ${dates.parseTimestamp(period.timeRange.end)}`}
                    arrow
                  >
                    <PresencePeriod
                      start={
                        (dates.parseTimestamp(period.timeRange.start) /
                          totalDuration) *
                        100
                      }
                      end={
                        (dates.parseTimestamp(period.timeRange.end) /
                          totalDuration) *
                        100
                      }
                      color={getColor(i)}
                      onClick={() =>
                        controls?.setSeekTime?.(
                          dates.parseTimestamp(period.timeRange.start),
                        )
                      }
                    />
                  </Tooltip>
                ))}
            </Timeline>
          </ItemRow>
        ))}
      </Chart>
      {presences.length > 0 && (
        <XAxis sx={{ marginBottom: '20px' }}>
          {timeMarkers.map(time => (
            <TimeMarker
              key={time}
              variant='caption'
              pos={(time / totalDuration) * 100}
            >
              {formatTime(time)}
            </TimeMarker>
          ))}
        </XAxis>
      )}

      {dialogOpen && (
        <PresenceDialog
          name={selectedItem?.label}
          onClose={handleDialogClose}
        />
      )}
    </ChartContainer>
  );
};

const ChartContainer = styled(Box)(() => ({ marginRight: 20 }));

const Chart = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const ItemRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
});

const Timeline = styled(Box)({
  flexGrow: 1,
  height: 20,
  backgroundColor: '#EBEBEB',
  position: 'relative',
  borderRadius: 15,
});

const PresencePeriod = styled(Box)<{
  start: number;
  end: number;
  color: string;
}>(({ start, end, color }) => ({
  position: 'absolute',
  height: '100%',
  backgroundColor: color,
  left: `${start}%`,
  width: `${end - start}%`,
  borderRadius: 15,
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const XAxis = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 5,
  marginLeft: avatarTotal,
  position: 'relative',
});

const TimeMarker = styled(Typography)<{ pos: number }>(({ pos }) => ({
  position: 'absolute',
  left: `${pos}%`,
  transform: 'translateX(-50%)',
}));

export default PresenceTimeline;
