import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import {
  Ambassador,
  AmbassadorList,
  AmbassadorType,
  UnknownAmbassador,
  UnknownIdentifier,
  UnknownPayload,
} from '../../models/Ambassador';
import { countries, CountryMap } from './countries';

interface AmbassadorModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (
    ambassador: AmbassadorType,
    ambassadorIds: UnknownIdentifier[],
  ) => void;
  ambassadors: AmbassadorList;
  unknown?: boolean;
}

const extractUnknownIdentifier = (a: AmbassadorType): UnknownIdentifier => {
  const data = a as UnknownAmbassador;
  return {
    asset_id: data.source_asset_id,
    source: data.source_source,
    unknown_id: data.source_unknown_id,
  };
};

const AmbassadorModal = ({
  open,
  onClose,
  onSave,
  ambassadors,
  unknown = false,
}: AmbassadorModalProps) => {
  const [mode, setMode] = useState<
    'edit' | 'create' | 'search' | 'createFromUnknown'
  >('search');
  const [editedAmbassador, setEditedAmbassador] =
    useState<AmbassadorType | null>(null);
  const [ambassadorIds, setAmbassadorIds] = useState<UnknownIdentifier[]>([]);
  const [trainingImages, setTrainingImages] = useState<File[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Ambassador[]>([]);
  // const [pendingAmbassador, setPendingAmbassador] = useState<Ambassador | null>(
  //   null,
  // );
  const [selectedAmbassador, setSelectedAmbassador] =
    useState<Ambassador | null>(null);

  const { api } = useAuthenticatedUser();

  useEffect(() => {
    if (ambassadors.length > 0) {
      setEditedAmbassador(ambassadors[0] as UnknownAmbassador);
      const buff = ambassadors.map(extractUnknownIdentifier);
      setAmbassadorIds(buff);
      if (unknown) setMode('search');
      else setMode('edit');
    } else {
      setMode('create');
    }
  }, [ambassadors, unknown]);

  useEffect(() => {
    const searchAmbassadors = async () => {
      if (searchQuery) {
        try {
          const results = await api.ambassadors.search(searchQuery);
          setSearchResults(results);
        } catch (error) {
          console.error('Failed to search ambassadors:', error);
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    };

    const debounceTimeout = setTimeout(searchAmbassadors, 300);
    return () => clearTimeout(debounceTimeout);
  }, [searchQuery, api.ambassadors]);

  const countryOptions = useMemo(
    () =>
      Object.entries(countries as CountryMap).map(([code, label]) => ({
        code,
        label: label.label,
      })),
    [],
  );

  const handleImageDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    setTrainingImages([...trainingImages, ...imageFiles]);
  };

  // const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files) {
  //     const files = Array.from(event.target.files);
  //     setTrainingImages([...trainingImages, ...files]);
  //   }
  // };

  // const removeTrainingImage = (index: number) => {
  //   setTrainingImages(trainingImages.filter((_, i) => i !== index));
  // };

  const getDisplayValue = (value: any) => {
    return value || 'Unknown';
  };

  const handleFieldChange = (field: keyof Ambassador, value: any) => {
    if (editedAmbassador) {
      setEditedAmbassador({
        ...editedAmbassador,
        [field]: value,
      });
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setSelectedAmbassador(null);
  };

  const handleCreateNew = () => {
    setMode('createFromUnknown');
    if (ambassadors.length > 0)
      setEditedAmbassador(ambassadors[0] as UnknownAmbassador);
    setAmbassadorIds(ambassadors.map(extractUnknownIdentifier));
  };

  const handlePlayVideo = () => {
    if (editedAmbassador?.video_id) {
      window.open(`/videos/${editedAmbassador.video_id}`, '_blank');
    }
  };

  const handleCancel = () => {
    if (ambassadors && ambassadors.length > 0) {
      setEditedAmbassador(ambassadors[0] as UnknownAmbassador);
      setAmbassadorIds(ambassadors.map(extractUnknownIdentifier));
      setMode('edit');
    } else {
      setMode('create');
      setEditedAmbassador(null);
      setAmbassadorIds([]);
    }
    //setPendingAmbassador(null);
    setSearchQuery('');
    setSelectedAmbassador(null);
    onClose();
  };

  const handleSave = () => {
    if (editedAmbassador) {
      onSave(editedAmbassador, ambassadorIds);
    }
  };

  const handleApplyMapping = async () => {
    if (selectedAmbassador && editedAmbassador) {
      try {
        const ambassador_data = editedAmbassador as UnknownAmbassador;
        const payload: UnknownPayload = {
          asset_id: ambassador_data.source_asset_id,
          source: ambassador_data.source_source,
          unknown_id: ambassador_data.source_unknown_id,
          unknown_data: selectedAmbassador as Ambassador,
          blob_storage_path: ambassador_data.image_url,
          additional_faces: [],
        };
        await api.ambassadors.mapUnknownToExisting(ambassadorIds, payload);
        onClose();
      } catch (error) {
        console.error('Failed to map ambassador:', error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 3,
        }}
      >
        {mode === 'search' ? (
          <Typography variant='h6'>Search Existing Ambassador</Typography>
        ) : (
          <Typography variant='h6'>
            {mode === 'edit' ? 'Edit Ambassador' : 'New Ambassador'}
          </Typography>
        )}
        <IconButton onClick={onClose} size='small'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {mode === 'search' ? (
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              placeholder='Search by name...'
              value={searchQuery}
              onChange={e => handleSearch(e.target.value)}
              sx={{ mb: 3 }}
            />

            {searchResults.length > 0 ? (
              <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
                {searchResults.map(result => (
                  <Box
                    key={result.celebrity_id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      p: 2,
                      cursor: 'pointer',
                      bgcolor:
                        selectedAmbassador?.vi_id === result.vi_id
                          ? 'action.selected'
                          : 'transparent',
                      '&:hover': { bgcolor: 'action.hover' },
                    }}
                    onClick={() => setSelectedAmbassador(result)}
                  >
                    <Box
                      component='img'
                      src={result.image_url || ''}
                      sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                    />
                    <Box>
                      <Typography variant='subtitle1'>{result.name}</Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {result.profession || 'unknown'} •{' '}
                        {result.nationality || 'unknown'}
                      </Typography>
                    </Box>
                  </Box>
                ))}
                {selectedAmbassador && (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
                  >
                    <Button
                      variant='contained'
                      onClick={handleApplyMapping}
                      color='primary'
                    >
                      Apply
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center', my: 3 }}>
                <Typography color='text.secondary'>No matches found</Typography>
                <Button
                  variant='contained'
                  onClick={handleCreateNew}
                  sx={{ mt: 2 }}
                >
                  Add as New Ambassador
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <>
            {mode === 'edit' && editedAmbassador && (
              <Box sx={{ mb: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Box
                    component='img'
                    src={editedAmbassador.image_url || ''}
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      objectFit: 'cover',
                      mr: 2,
                    }}
                  />
                  <Box>
                    <Typography variant='subtitle1'>
                      {editedAmbassador.name || ''}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      Added on{' '}
                      {new Date().toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {editedAmbassador.appearances?.length || 0} Appearances
                    </Typography>
                    {unknown && editedAmbassador.video_id && (
                      <Button
                        startIcon={<PlayArrowIcon />}
                        onClick={handlePlayVideo}
                        sx={{ mt: 1 }}
                        size='small'
                      >
                        Play Video
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            )}

            <TextField
              fullWidth
              size='small'
              value={editedAmbassador?.name || ''}
              onChange={e => {
                handleFieldChange('name', e.target.value);
              }}
              placeholder='Enter name'
              sx={{ mb: 3 }}
            />

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 3,
                mb: 4,
              }}
            >
              <Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ mb: 1 }}
                >
                  Image URL
                </Typography>
                <TextField
                  fullWidth
                  size='small'
                  value={editedAmbassador?.image_url || ''}
                  onChange={e => handleFieldChange('image_url', e.target.value)}
                  placeholder='Enter image URL'
                />
              </Box>

              <Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ mb: 1 }}
                >
                  Birth date
                </Typography>
                <TextField
                  type='date'
                  fullWidth
                  size='small'
                  value={
                    editedAmbassador?.birth_date
                      ? new Date(editedAmbassador.birth_date)
                          .toISOString()
                          .split('T')[0]
                      : ''
                  }
                  onChange={e =>
                    handleFieldChange(
                      'birth_date',
                      new Date(e.target.value).getTime(),
                    )
                  }
                  placeholder='Unknown'
                />
              </Box>

              <Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ mb: 1 }}
                >
                  Gender
                </Typography>
                <TextField
                  select
                  fullWidth
                  size='small'
                  value={getDisplayValue(editedAmbassador?.gender)}
                  onChange={e => handleFieldChange('gender', e.target.value)}
                >
                  <MenuItem value='Unknown'>Unknown</MenuItem>
                  <MenuItem value='Female'>Female</MenuItem>
                  <MenuItem value='Male'>Male</MenuItem>
                  <MenuItem value='Other'>Other</MenuItem>
                </TextField>
              </Box>

              <Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ mb: 1 }}
                >
                  Nationalities
                </Typography>
                <Autocomplete
                  multiple
                  size='small'
                  options={countryOptions}
                  getOptionLabel={option =>
                    typeof option === 'string' ? option : option.label
                  }
                  value={
                    editedAmbassador?.nationality?.length
                      ? editedAmbassador.nationality.map(code => ({
                          code,
                          label: countries[code]?.label || code,
                        }))
                      : [{ code: 'unknown', label: 'Unknown' }]
                  }
                  onChange={(_, newValue) => {
                    handleFieldChange(
                      'nationality',
                      newValue.map(v => (v.code === 'unknown' ? null : v.code)),
                    );
                  }}
                  renderInput={params => <TextField {...params} />}
                />
              </Box>

              <Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ mb: 1 }}
                >
                  SkinTone
                </Typography>
                <TextField
                  select
                  fullWidth
                  size='small'
                  value={getDisplayValue(editedAmbassador?.skin_tone)}
                  onChange={e => handleFieldChange('skin_tone', e.target.value)}
                >
                  <MenuItem value='Unknown'>Unknown</MenuItem>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(tone => (
                    <MenuItem key={tone} value={tone}>
                      {tone}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ mb: 1 }}
                >
                  Profession
                </Typography>
                <Autocomplete
                  multiple
                  freeSolo
                  size='small'
                  options={[]}
                  value={
                    editedAmbassador?.profession?.length
                      ? editedAmbassador.profession
                      : ['Unknown']
                  }
                  onChange={(_, newValue) => {
                    handleFieldChange(
                      'profession',
                      newValue.filter(profession => profession !== 'Unknown'),
                    );
                  }}
                  renderInput={params => <TextField {...params} />}
                />
              </Box>

              <Box sx={{ gridColumn: '1 / -1' }}>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ mb: 1 }}
                >
                  Description
                </Typography>
                <TextField
                  fullWidth
                  size='small'
                  multiline
                  rows={4}
                  value={getDisplayValue(editedAmbassador?.description)}
                  onChange={e =>
                    handleFieldChange('description', e.target.value)
                  }
                  placeholder='Unknown'
                />
              </Box>
            </Box>

            {mode === 'createFromUnknown' && (
              <Box>
                <Typography variant='subtitle2' sx={{ mb: 2 }}>
                  Training Images
                </Typography>
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 2,
                    p: 3,
                    backgroundColor: '#F9FAFB',
                    textAlign: 'center',
                    mb: 1,
                  }}
                  onDrop={handleImageDrop}
                  onDragOver={e => e.preventDefault()}
                >
                  <Typography>Drag and Drop Images Here</Typography>
                </Box>
                <Typography variant='caption' color='text.secondary'>
                  Minimum of 10 pictures required. Supported formats: PNG/JPEG.
                  Maximum file size: 10 MB per file.
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                mt: 4,
                pt: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
              }}
            >
              <Button
                onClick={handleCancel}
                variant='outlined'
                sx={{
                  borderRadius: '8px',
                  px: 3,
                  borderColor: '#D0D5DD',
                  color: 'text.primary',
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                variant='contained'
                sx={{
                  borderRadius: '8px',
                  px: 3,
                  backgroundColor: '#7F56D9',
                  '&:hover': {
                    backgroundColor: '#6941C6',
                  },
                }}
              >
                {mode === 'edit' ? 'Save Changes' : 'Add as New Ambassador'}
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AmbassadorModal;
