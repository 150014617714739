import { DateRange, RestartAlt } from '@mui/icons-material';
import { Box, IconButton, Popover, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import NormalizedSelect from '../../components/NormalizedSelect';
import PresetDateRangePicker from '../../components/PresetDateRangePicker';
import { StyledFiltersBar } from '../../theme/bars';
import useFilters from './context';

interface FiltersBarProps {
  children?: React.ReactNode;
}
const FiltersBar: React.FC<FiltersBarProps> = props => {
  const { children } = props;
  const { filters, setters, availableFilters, reset, noDateRange } =
    useFilters();
  const { platform, brand, country, campaign } = filters;
  const { platforms, brands, countries, campaigns } = availableFilters;
  const { setPlatform, setBrand, setCountry, setCampaign } = setters;
  return (
    <StyledFiltersBar direction='row' spacing={5} alignItems='center'>
      <NormalizedSelect
        label='Platform'
        value={platform}
        onChange={setPlatform}
        options={platforms}
      />
      <NormalizedSelect
        label='Brand'
        value={brand}
        onChange={setBrand}
        options={brands}
      />
      <NormalizedSelect
        label='Country'
        value={country}
        onChange={setCountry}
        options={countries}
      />
      <NormalizedSelect
        label='Campaign'
        value={campaign}
        onChange={setCampaign}
        options={campaigns}
      />
      {!noDateRange && <DateFilterMenu />}
      <Tooltip title='Reset Filters'>
        <IconButton onClick={reset} color='primary'>
          <RestartAlt />
        </IconButton>
      </Tooltip>
      <Box flexGrow={1} />
      {children}
    </StyledFiltersBar>
  );
};
export default FiltersBar;

const DateFilterMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    dateRange,
    setters: { setDateRange },
  } = useFilters();
  return (
    <>
      <Tooltip title='Date Range'>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
          <DateRange />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box padding={2}>
          <PresetDateRangePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </Box>
      </Popover>
    </>
  );
};
