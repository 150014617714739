import { Box } from '@mui/material';
import { ChevronDown, ChevronUp, Pause, Play } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { IMomentResult } from '../../models/moments';
import { getIconForType } from './VideoDetails';

const styles = `
  .video-timeline {
    width: Calc(100% - 2rem);
    border-radius: 0.5rem;
    color: white;
    background-color: #1f2937;
    padding: 1rem;
  }

  .video-timeline-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .control-icon {
    color: #E96470;
    margin-right: 0.5rem;
    cursor: pointer;
  }

  .time-display {
    font-size: 0.875rem;
  }

  .timeline-container {
    position: relative;
    background-color: #c8c8ff;
    border-radius: 0.375rem;
    cursor: pointer;
    overflow: hidden;
  }

  .minimal-timeline {
    height: 0.5rem;
  }

  .expanded-timeline {
    min-height: 3rem;
    padding-bottom: 1.5rem;
  }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #0022ff;
    opacity: 0.4;
    pointer-events: none;
  }

  .timeline-item {
    position: absolute;
    background-color: #e96470;
    border-radius: 0 10px;
    overflow: hidden;
    pointer-events: none;
  }

  .minimal-item {
    height: 100%;
    opacity: 0.7;
  }

  .expanded-item {
    height: 2rem;
    min-width: 40px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .timeline-item-content {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    min-width: 20px;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
  }

  .timeline-item-image {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .timeline-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mode-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
`;

interface TimelineItemProps {
  type: string;
  content: string;
  image?: string;
  timeRange: {
    start: number;
    end: number;
  };
  videoDuration: number;
  row: number;
  isMinimal: boolean;
}

const TimelineItem: React.FC<TimelineItemProps> = ({
  type,
  content,
  timeRange,
  videoDuration,
  image,
  row,
  isMinimal,
}) => {
  const startPercentage = (timeRange.start / videoDuration) * 100;
  const width = Math.min(
    ((timeRange.end - timeRange.start) / videoDuration) * 100,
    100 - startPercentage,
  );

  return (
    <div
      className={`timeline-item ${isMinimal ? 'minimal-item' : 'expanded-item'}`}
      style={{
        left: `${startPercentage}%`,
        width: `${width}%`,
        top: isMinimal ? '0' : `${(row + 0.2) * 2.5}rem`,
      }}
    >
      {!isMinimal && (
        <div className='timeline-item-content'>
          {type === 'People' ? (
            <img src={image} alt={content} className='timeline-item-image' />
          ) : (
            <span className='timeline-item-text'>{getIconForType(type)}</span>
          )}
          <span className='timeline-item-text'>{content}</span>
        </div>
      )}
    </div>
  );
};

interface VideoTimelineProps {
  result: IMomentResult;
  currentTime: number;
  onSeek: (time: number) => void;
  onPlayPause: () => void;
  isPlaying: boolean;
  duration: number;
}

const VideoTimeline: React.FC<VideoTimelineProps> = ({
  result,
  currentTime,
  onSeek,
  onPlayPause,
  isPlaying,
  duration,
}) => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const [timelineItems, setTimelineItems] = useState<any[]>([]);
  const [containerHeight, setContainerHeight] = useState<number>(3);
  const [isMinimal, setIsMinimal] = useState<boolean>(true);

  useEffect(() => {
    const items = result.results.flatMap(result =>
      result.items.flatMap(item =>
        item.appearances.map(appearance => ({
          type: result.type,
          content: item.content,
          image: item.image,
          imageID: item.imageID,
          timeRange: appearance.timeRange,
        })),
      ),
    );

    items.sort((a, b) => a.timeRange.start - b.timeRange.start);

    const rows: number[] = [];
    const assignedItems = items.map(item => {
      let row = 0;
      while (rows[row] && rows[row] > item.timeRange.start) {
        row++;
      }
      rows[row] = item.timeRange.end;
      return { ...item, row };
    });

    setTimelineItems(assignedItems);

    const maxRow = Math.max(...assignedItems.map(item => item.row));
    setContainerHeight((maxRow + 1) * 2.5);
  }, [result]);

  const handleTimelineClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const clickPosition = event.clientX - rect.left;
      const clickPercentage = clickPosition / rect.width;
      const seekTime = duration * clickPercentage;
      onSeek(seekTime);
    }
  };

  const formatTime = (milliseconds: number): string => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const toggleMode = () => {
    setIsMinimal(!isMinimal);
  };

  return (
    <>
      <style>{styles}</style>
      <Box className='video-timeline'>
        <div className='video-timeline-header'>
          {isPlaying ? (
            <Pause className='control-icon' onClick={onPlayPause} />
          ) : (
            <Play className='control-icon' onClick={onPlayPause} />
          )}
          <div className='time-display'>
            {formatTime(currentTime)} / {formatTime(duration)}
          </div>
          <div className='mode-toggle' onClick={toggleMode}>
            {isMinimal ? <ChevronDown /> : <ChevronUp />}
          </div>
        </div>
        <div
          ref={timelineRef}
          className={`timeline-container ${isMinimal ? 'minimal-timeline' : 'expanded-timeline'}`}
          onClick={handleTimelineClick}
          style={{ height: isMinimal ? '0.5rem' : `${containerHeight}rem` }}
        >
          <div
            className='progress-bar'
            style={{ width: `${(currentTime / duration) * 100}%` }}
          />
          {timelineItems.map((item, index) => (
            <TimelineItem
              key={index}
              type={item.type}
              image={item.image}
              content={item.content}
              timeRange={item.timeRange}
              videoDuration={duration}
              row={item.row}
              isMinimal={isMinimal}
            />
          ))}
        </div>
      </Box>
    </>
  );
};

export default VideoTimeline;
