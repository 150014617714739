import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import nopicture_f from '../../assets/nopicture-f.png';
import nopicture_m from '../../assets/nopicture-m.png';
import { useAuthenticatedUser } from '../../contexts/auth';
import { Ambassador, UnknownIdentifier } from '../../models/Ambassador';
import AmbassadorModal from './AmbassadorModal';
import { countries } from './countries';

const AmbassadorsManager = () => {
  const [ambassadors, setAmbassadors] = useState<Ambassador[]>([]);
  const [loading, setLoading] = useState(false);
  const [editingAmbassador, setEditingAmbassador] = useState<Ambassador | null>(
    null,
  );
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { api } = useAuthenticatedUser();

  useEffect(() => {
    const loadAmbassadors = async () => {
      setLoading(true);
      try {
        const data = await api.ambassadors.all();
        setAmbassadors(data);
      } catch (error) {
        console.error('Error loading ambassadors:', error);
      }
      setLoading(false);
    };
    loadAmbassadors();
  }, [api.ambassadors]);

  const handleDelete = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this ambassador?'))
      return;

    try {
      await api.ambassadors.delete(id);
      setAmbassadors(prev => prev.filter(amb => amb.celebrity_id !== id));
    } catch (error) {
      console.error('Error deleting ambassador:', error);
    }
  };

  const handleEdit = (ambassador: Ambassador) => {
    setEditingAmbassador({
      ...ambassador,
      profession:
        typeof ambassador.profession === 'string'
          ? [ambassador.profession]
          : ambassador.profession,
      nationality:
        typeof ambassador.nationality === 'string'
          ? [ambassador.nationality]
          : ambassador.nationality,
    });
    setEditDialogOpen(true);
  };

  const handleCreate = () => {
    setEditingAmbassador({
      celebrity_id: 0,
      name: '',
      gender: '',
      birth_date: new Date(),
      profession: [],
      skin_tone: '',
      nationality: [],
      image_url: '',
      isAmbassador: true,
      description: '',
      appearances: [],
      detected_at: new Date(),
      thumbnail_id: '',
      video_id: '',
      vi_id: '',
      video_brand: '',
      video_country: '',
    });
    setEditDialogOpen(true);
  };

  const handleCloseEdit = () => {
    setEditingAmbassador(null);
    setEditDialogOpen(false);
  };

  const handleSaveEdit = async (
    updatedAmbassador: Ambassador,
    ambassadorIds: UnknownIdentifier[],
  ) => {
    try {
      if (updatedAmbassador.celebrity_id === 0) {
        // Create new ambassador
        const created = await api.ambassadors.create(updatedAmbassador);
        setAmbassadors(prev => [...prev, created]);
      } else {
        // Update existing ambassador
        await api.ambassadors.update(
          updatedAmbassador.celebrity_id,
          updatedAmbassador,
        );
        setAmbassadors(prev =>
          prev.map(c =>
            c.celebrity_id === updatedAmbassador.celebrity_id
              ? updatedAmbassador
              : c,
          ),
        );
      }
      handleCloseEdit();
    } catch (error) {
      console.error('Error saving ambassador:', error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'image_url',
      headerName: 'Photo',
      width: 100,
      renderCell: params => {
        const defaultPhoto =
          params.row.gender === 'Female' ? nopicture_f : nopicture_m;
        return (
          <img
            src={
              params.value && params.value !== 'None'
                ? params.value
                : defaultPhoto
            }
            alt={params.row.name}
            width={50}
          />
        );
      },
    },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'gender', headerName: 'Gender', flex: 1 },
    { field: 'birth_date', headerName: 'Birth Date', flex: 1 },
    {
      field: 'job',
      headerName: 'Profession',
      flex: 1,
      renderCell: params => (
        <Box>
          {Array.isArray(params.value) ? params.value.join(', ') : params.value}
        </Box>
      ),
    },
    { field: 'skin_tone', headerName: 'Skin Tone', flex: 1 },
    {
      field: 'nationality',
      headerName: 'Nationality',
      flex: 1,
      renderCell: params => (
        <Box>
          {Array.isArray(params.value)
            ? params.value
                .map(code => countries[code]?.label || code)
                .join(', ')
            : countries[params.value]?.label || params.value}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 1,
      getActions: params => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label='Edit'
          onClick={() => handleEdit(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Delete'
          onClick={() => handleDelete(params.row.celebrity_id)}
        />,
      ],
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <Button variant='contained' color='primary' onClick={handleCreate}>
          Add New Ambassador
        </Button>
      </Box>

      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={ambassadors}
          columns={columns}
          loading={loading}
          disableRowSelectionOnClick
          autoPageSize
          getRowId={row => row.celebrity_id}
        />
      </Box>

      <AmbassadorModal
        open={editDialogOpen}
        onClose={handleCloseEdit}
        ambassadors={editingAmbassador ? [editingAmbassador] : []}
        onSave={handleSaveEdit}
      />
    </Box>
  );
};

export default AmbassadorsManager;
