// PresenceDialog.tsx
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../contexts/auth';
import useVideoDashboard from '../features/VideoDashboard/context';

interface PresenceDialogProps {
  name?: string;
  onClose: () => void;
}

type Person = {
  name: string;
  age: number;
  skinTone: number;
  nationality: string;
  profession: string;
  gender: string;
  image: string;
  appearances?: {
    timeRange: {
      start: number;
      end: number;
    };
  }[];
};

const PresenceDialog: React.FC<PresenceDialogProps> = ({ name, onClose }) => {
  const { video } = useVideoDashboard();
  const { creativeData } = video;
  const [editMode, setEditMode] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const [editedPerson, setEditedPerson] = useState<Person | null>(null);
  const [showPersonSearch, setShowPersonSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [pendingPerson, setPendingPerson] = useState<Person | null>(null);
  const [updateScope, setUpdateScope] = useState<'single' | 'all'>('single');

  const { api } = useAuthenticatedUser();

  useEffect(() => {
    if (name && creativeData?.people) {
      const person = creativeData.people.find((p: Person) => p.name === name);
      setSelectedPerson(person || null);
      setEditedPerson(person || null);
    }
  }, [name, creativeData]);

  useEffect(() => {
    const searchCelebrities = async () => {
      if (searchQuery) {
        try {
          const results = await api.ambassadors.search(searchQuery);
          setSearchResults(results);
        } catch (error) {
          console.error('Failed to search celebrities:', error);
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    };

    const debounceTimeout = setTimeout(searchCelebrities, 300);
    return () => clearTimeout(debounceTimeout);
  }, [searchQuery, api.ambassadors]);

  if (!selectedPerson || !editedPerson) return null;

  const handleChange =
    (field: keyof Person) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setEditedPerson(prev => ({
        ...prev!,
        [field]:
          field === 'age' || field === 'skinTone' ? Number(value) : value,
      }));
    };

  const handlePersonChange = (newPerson: Person) => {
    setPendingPerson(newPerson);
  };

  const handleSave = async () => {
    try {
      // Encode the person name if it contains "Unknown #" pattern
      const encodeName = (name: string) => {
        return encodeURIComponent(name);
      };

      const oldName = encodeName(selectedPerson.name);

      if (pendingPerson) {
        const { appearances: _, ...personWithoutAppearances } = pendingPerson;
        await api.videos.updatePerson(
          video._id,
          oldName,
          personWithoutAppearances,
        );
        setSelectedPerson(pendingPerson);
        setEditedPerson(pendingPerson);
        setPendingPerson(null);
      } else if (editMode) {
        const { appearances: _, ...editedPersonWithoutAppearances } =
          editedPerson;
        if (
          updateScope === 'single' ||
          selectedPerson.name.startsWith('Unknown #')
        ) {
          // Update person for this video only
          await api.videos.updatePerson(
            video._id,
            oldName,
            editedPersonWithoutAppearances,
          );
        } else {
          // Update person across all videos
          await api.videos.updatePeopleByName(
            oldName,
            editedPersonWithoutAppearances,
          );
        }
        setSelectedPerson(editedPerson);
      }
      setEditMode(false);
      setShowPersonSearch(false);
      setSearchQuery('');
      setUpdateScope('single');
    } catch (error) {
      console.error('Failed to update person:', error);
      // TODO: Add error handling UI
    }
  };

  const handleCancel = () => {
    setEditedPerson(selectedPerson);
    setPendingPerson(null);
    setEditMode(false);
    setShowPersonSearch(false);
    setSearchQuery('');
    setUpdateScope('single');
  };

  return (
    <Dialog open={!!selectedPerson} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {editMode ? (
            <TextField
              value={editedPerson.name}
              onChange={handleChange('name')}
              size='small'
              sx={{ minWidth: 200 }}
            />
          ) : (
            <Typography variant='h6'>{selectedPerson.name}</Typography>
          )}
          {!editMode && !pendingPerson && (
            <Button
              variant='outlined'
              size='small'
              onClick={() => setShowPersonSearch(true)}
            >
              Replace Person
            </Button>
          )}
        </Box>
        <IconButton onClick={onClose} size='small'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showPersonSearch ? (
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              label='Search person by name'
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
              {searchResults.map(person => (
                <Box
                  key={person.name}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 1,
                    cursor: 'pointer',
                    bgcolor:
                      pendingPerson?.name === person.name
                        ? 'action.selected'
                        : 'transparent',
                    '&:hover': { bgcolor: 'action.hover' },
                  }}
                  onClick={() => handlePersonChange(person)}
                >
                  <Box>
                    <Typography variant='subtitle1'>{person.name}</Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {person.profession} • {person.nationality}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 4, p: 2 }}>
            <Box sx={{ flex: '0 0 200px' }}>
              <img
                src={
                  editMode
                    ? editedPerson.image
                    : pendingPerson
                      ? pendingPerson.image
                      : selectedPerson.image
                }
                alt={selectedPerson.name}
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  marginBottom: '8px',
                }}
              />
              {editMode && (
                <TextField
                  fullWidth
                  label='Image URL'
                  value={editedPerson.image}
                  onChange={handleChange('image')}
                  size='small'
                />
              )}
            </Box>
            <Box
              sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              {editMode ? (
                <>
                  <TextField
                    label='Age'
                    type='number'
                    value={editedPerson.age}
                    onChange={handleChange('age')}
                  />
                  <TextField
                    label='Gender'
                    value={editedPerson.gender}
                    onChange={handleChange('gender')}
                  />
                  <TextField
                    label='Skin Tone'
                    type='number'
                    value={editedPerson.skinTone}
                    onChange={handleChange('skinTone')}
                  />
                  <TextField
                    label='Nationality'
                    value={editedPerson.nationality}
                    onChange={handleChange('nationality')}
                  />
                  <TextField
                    label='Profession'
                    value={editedPerson.profession}
                    onChange={handleChange('profession')}
                  />
                  {!selectedPerson.name.startsWith('Unknown #') && (
                    <FormControl component='fieldset'>
                      <FormLabel component='legend'>Update Scope</FormLabel>
                      <RadioGroup
                        value={updateScope}
                        onChange={e =>
                          setUpdateScope(e.target.value as 'single' | 'all')
                        }
                      >
                        <FormControlLabel
                          value='single'
                          control={<Radio />}
                          label='Update for this video only'
                        />
                        <FormControlLabel
                          value='all'
                          control={<Radio />}
                          label='Update for all videos'
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </>
              ) : (
                <>
                  <Typography variant='body1'>
                    <strong>Age:</strong>{' '}
                    {pendingPerson ? pendingPerson.age : selectedPerson.age}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Gender:</strong>{' '}
                    {pendingPerson
                      ? pendingPerson.gender
                      : selectedPerson.gender}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Skin Tone:</strong>{' '}
                    {pendingPerson
                      ? pendingPerson.skinTone
                      : selectedPerson.skinTone}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Nationality:</strong>{' '}
                    {pendingPerson
                      ? pendingPerson.nationality
                      : selectedPerson.nationality}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Profession:</strong>{' '}
                    {pendingPerson
                      ? pendingPerson.profession
                      : selectedPerson.profession}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Total Appearances:</strong>{' '}
                    {(pendingPerson
                      ? pendingPerson.appearances
                      : selectedPerson.appearances
                    )?.length || 0}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            mt: 2,
            p: 2,
          }}
        >
          {(editMode || showPersonSearch || pendingPerson) && (
            <Button variant='outlined' onClick={handleCancel}>
              Cancel
            </Button>
          )}
          <Button
            variant='contained'
            onClick={() =>
              editMode || showPersonSearch || pendingPerson
                ? handleSave()
                : setEditMode(true)
            }
          >
            {editMode || showPersonSearch || pendingPerson
              ? 'Save Changes'
              : 'Edit'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PresenceDialog;
