import { ICreativeData } from './creativeData';
import { User, WithRoles } from './users';

export const platforms = [
  'YouTube',
  'Facebook',
  'Instagram',
  'Twitter',
  'TikTok',
  'Unknown',
] as const;
export type Platform = (typeof platforms)[number];

export const sources = ['internal', 'opera', 'bic'] as const;
export type Source = (typeof sources)[number];

export interface Model<T = string> {
  _id: T;
}

interface MeasureData {
  value: number;
  history: number[];
}

export interface IVideoSummary {
  VideosCount: number;
  VideosDuration: number;
  Measures: {
    Impressions: MeasureData;
    WatchTime: MeasureData;
    Spend: MeasureData;
    CTR: MeasureData;
    ConversionRate: MeasureData;
    Views: MeasureData;
  };
  CreativeData: any;
}

export interface IBaseMetadata {
  source: Source;
  assetId: string;
  name: string;
  platform: Platform;
  brand: string;
  country: string;
  campaign: string;
  // not used for now
  // videoPath?: string;
  // thumbnailPath?: string;
  description?: string;
  videoUrl?: string;
  thumbnailUrl?: string;
}

export interface IInflightMetadata extends IBaseMetadata {
  publishedAt: string;
  externalUrl: string;
}

export interface IVideoMetric {
  date: Date;
  spendUsd: number;
  spendEur: number;
  views: number;
  impressions: number;
  CTR?: number;
  ConversionRate?: number;
  RetentionRate?: number;
  WatchTime?: number;
}

export interface IInflightVideo extends Model {
  metadata: IInflightMetadata;
  measures: IVideoMetric[];
  creativeData?: ICreativeData;
  type: 'inflight';
}

export type IVideo = IInflightVideo | IPreflightVideo;

export type AnalyzedVideo<T extends IVideo = IVideo> = Required<T> & {
  metadata: Required<T['metadata']>;
};
export const perfKeys = [
  'views',
  'impressions',
  'CTR',
  'ConversionRate',
  'RetentionRate',
  'WatchTime',
] as const;
export type PerfKey = (typeof perfKeys)[number];
export function isPerfKey(key: string): key is PerfKey {
  return perfKeys.includes(key as PerfKey);
}

export interface IPreflightMetadata extends IBaseMetadata {
  // authorId: string; // not used for now
  objectives: PerfKey[];
}
export interface IPreflightVideo extends Model {
  metadata: IPreflightMetadata;
  indexation: Indexation;
  author: WithRoles<User>;
  creativeData?: ICreativeData;
  type: 'preflight';
}
export interface Indexation {
  ci_id: string;
  source: Source;
  asset_id: string;
  brand: string;
  download_url?: string;
  download_status: IndexationStatus;
  video_blob_path?: string;
  avi_status: IndexationStatus;
  avi_id?: string;
  postprocess_status: IndexationStatus;
  version?: string;
  created_at: Date;
  completed_at?: Date;
}
// !! Order is important here, it will be used to sort the indexations and get the global status
export const indexationStatuses = [
  'completed',
  'pending',
  'processing',
  'failed',
] as const;
export type IndexationStatus = (typeof indexationStatuses)[number];

export type CreatePreflightVideo = Omit<
  IPreflightVideo,
  '_id' | 'type' | 'indexation' | 'metadata' | 'author'
> & {
  metadata: Omit<IPreflightMetadata, 'assetId'>;
};

export function isAnalyzedVideo<T extends IVideo = IVideo>(
  video: T,
): video is AnalyzedVideo<T> {
  const {
    metadata: { videoUrl, thumbnailUrl },
    creativeData,
  } = video;
  return !!videoUrl && !!thumbnailUrl && !!creativeData;
}

export function compareIndexation(a: Indexation, b: Indexation) {
  return comparateStatus(a.postprocess_status, b.postprocess_status);
}
function comparateStatus(a: IndexationStatus, b: IndexationStatus) {
  return indexationStatuses.indexOf(a) - indexationStatuses.indexOf(b);
}

export interface Brand {
  BrandID: number;
  BrandName: string;
}
