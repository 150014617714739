import {
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  RoleName,
  User,
  userHasRole,
  WithPerms,
  WithRoles,
} from '../models/users';
import buildApi, { Api } from '../services/api';

// MSAL configuration
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/v2.0`,
    redirectUri: window.location.origin,
    validateAuthority: true,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

console.log('Client ID', process.env.REACT_APP_AZURE_CLIENT_ID);
console.log('Tenant ID', process.env.REACT_APP_AZURE_TENANT_ID);
// Authentication scopes
const loginRequest = {
  scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
};

// Initialize MSAL instance
let msalInstance: PublicClientApplication;

const initializeMsal = async () => {
  msalInstance = new PublicClientApplication(msalConfig as Configuration);
  await msalInstance.initialize();
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<AuthenticatedUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const getToken = async (): Promise<string | null> => {
    try {
      const account = msalInstance.getAllAccounts()[0];
      if (!account) {
        throw new Error('No account found');
      }
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      return response.accessToken;
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect(loginRequest);
        return null;
      }
      throw err;
    }
  };

  const refresh = useCallback(async () => {
    try {
      const token = await getToken();
      if (token) {
        const api = buildApi(token, { on401: refresh });
        const user = await api.users.me(token);
        setUser(user);
        setAccessToken(token);
      }
    } catch (err) {
      console.error('Token refresh failed:', err);
      logout();
    }
  }, []);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await initializeMsal();
        const response = await msalInstance.handleRedirectPromise();

        if (response) {
          const token = response.accessToken;
          const api = buildApi(token, { on401: refresh });
          const user = await api.users.me(token);
          setUser(user);
          setAccessToken(token);
        } else {
          const currentAccounts = msalInstance.getAllAccounts();
          if (currentAccounts.length > 0) {
            try {
              const token = await getToken();
              if (token) {
                const api = buildApi(token, { on401: refresh });
                const user = await api.users.me(token);
                setUser(user);
                setAccessToken(token);
              }
            } catch (err) {
              console.error('Failed to get token:', err);
              setError(
                err instanceof Error ? err.message : 'Failed to get token',
              );
            }
          }
        }
      } catch (err) {
        console.error('Auth initialization failed:', err);
        setError(
          err instanceof Error ? err.message : 'Failed to initialize auth',
        );
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [refresh]);

  const login = async () => {
    setLoading(true);
    try {
      await msalInstance.loginRedirect(loginRequest);
    } catch (err) {
      console.error('Login failed:', err);
      setError(err instanceof Error ? err.message : 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });
    setUser(null);
    setAccessToken(null);
  };

  const value = {
    accessToken,
    user,
    loading,
    error,
    login,
    refreshToken: refresh,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within a AuthProvider');
  return context;
}

export function useAuthentication() {
  const { loading, error, login } = useAuth();
  return { loading, error, login };
}

export function useAuthenticatedUser() {
  const { user, logout, accessToken, refreshToken } = useAuth();
  if (!accessToken || !user) throw new Error('User not authenticated');
  const api: Api = useMemo(
    () => buildApi(accessToken, { on401: refreshToken }),
    [accessToken, refreshToken],
  );
  return {
    user,
    api,
    logout,
    hasRole: (role: RoleName) => userHasRole(user, role),
  };
}

export function useAuthChecks() {
  const { user, accessToken } = useAuth();
  return {
    isAuthenticated: !!user && !!accessToken,
    hasRole: (role: RoleName) => !!user && userHasRole(user, role),
  };
}

interface AuthContextType {
  accessToken: string | null;
  loading: boolean;
  error: string | null;
  user: AuthenticatedUser | null;
  login: () => Promise<void>;
  refreshToken: () => Promise<void>;
  logout: () => void;
}

export interface TokenPayload {
  user: WithPerms<WithRoles<User>>;
  iat: number;
  exp: number;
}

export interface AuthenticatedUser extends WithPerms<WithRoles<User>> {
  iat: number;
  exp: number;
}
