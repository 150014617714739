import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

const batchState: { [key: string]: string | null } = {};
let batchTimeout: NodeJS.Timeout | null = null;

export default function useSearchParamState<
  S extends string | number | symbol | Date | undefined,
>(
  param: string,
  initialValue?: S,
  parser: (value: string) => S = (value: string) => value as S,
  serializer: (value: S) => string = (value: S) => value && value.toString(),
): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<S | undefined>(() => {
    const paramValue = searchParams.get(param);
    return paramValue ? parser(paramValue) : initialValue;
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableParser = useCallback(parser, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableSerializer = useCallback(serializer, []);

  const applyBatch = useCallback(() => {
    if (!Object.keys(batchState).length) return;

    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      Object.entries(batchState).forEach(([key, value]) => {
        if (value === null) newParams.delete(key);
        else newParams.set(key, value);
      });
      return newParams;
    });

    // Clear the batch after applying
    for (const key in batchState) {
      delete batchState[key];
    }
    batchTimeout = null;
  }, [setSearchParams]);

  const setSearchParamState = useCallback(
    (newState: S | undefined | ((prevState?: S) => S | undefined)) => {
      const value = typeof newState === 'function' ? newState(state) : newState;

      // Update the shared batch state
      batchState[param] = value === undefined ? null : stableSerializer(value);

      // Schedule the batch application
      if (!batchTimeout) {
        batchTimeout = setTimeout(applyBatch, 0); // Apply in the next event loop
      }
    },
    [param, state, stableSerializer, applyBatch],
  );

  useEffect(() => {
    const paramRaw = searchParams.get(param);
    const paramValue = paramRaw ? stableParser(paramRaw) : undefined;

    // Sync state with URL if it has changed
    setState(prev => (paramValue !== prev ? paramValue : prev));
  }, [param, searchParams, stableParser]);

  return [state, setSearchParamState];
}
