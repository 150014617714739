import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { IStructure, Mapping } from '../../models/creativeDataStructure';

interface PropertyMapperProps {
  propertyName: string;
  structure: IStructure;
  setStructure: React.Dispatch<React.SetStateAction<IStructure>>;
  availableProperties: string[];
  path: string;
}

export const PropertyMapper: React.FC<PropertyMapperProps> = ({
  propertyName,
  structure,
  setStructure,
  availableProperties,
  path,
}) => {
  const handlePropertyMapping = (jsonProperty: string) => {
    setStructure(prev => {
      const existingMappingIndex = prev.mappings?.findIndex(
        mapping => mapping.targetProperty === propertyName,
      );

      let newMappings: Mapping[];

      if (jsonProperty === '') {
        // Remove mapping if empty string is selected
        newMappings =
          prev.mappings?.filter(m => m.targetProperty !== propertyName) || [];
      } else if (
        existingMappingIndex !== undefined &&
        existingMappingIndex !== -1
      ) {
        // Update existing mapping
        newMappings = [...(prev.mappings || [])];
        newMappings[existingMappingIndex] = {
          sourceProperty: jsonProperty,
          targetProperty: propertyName,
        };
      } else {
        // Add new mapping
        newMappings = [
          ...(prev.mappings || []),
          { sourceProperty: jsonProperty, targetProperty: propertyName },
        ];
      }

      return {
        ...prev,
        mappings: newMappings,
      };
    });
  };

  const getCurrentMapping = (): string => {
    const mapping = structure.mappings?.find(
      m => m.targetProperty === propertyName,
    );
    return mapping ? mapping.sourceProperty : '';
  };

  return (
    <FormControl fullWidth sx={{ mt: 1 }}>
      <InputLabel>{propertyName}</InputLabel>
      <Select
        value={getCurrentMapping()}
        onChange={e => handlePropertyMapping(e.target.value as string)}
        label={propertyName}
      >
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {availableProperties.map(prop => (
          <MenuItem key={prop} value={prop}>
            {prop}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
