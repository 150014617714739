export interface CountryMap {
  [code: string]: { label: string };
}
export const countries: CountryMap = {
  AFG: { label: 'Afghanistan' },
  AGO: { label: 'Angola' },
  ALB: { label: 'Albania' },
  ARE: { label: 'United Arab Emirates' },
  ARG: { label: 'Argentina' },
  ARM: { label: 'Armenia' },
  ATF: { label: 'French Southern Territories' },
  AUS: { label: 'Australia' },
  AUT: { label: 'Austria' },
  AZE: { label: 'Azerbaijan' },
  BDI: { label: 'Burundi' },
  BEL: { label: 'Belgium' },
  BEN: { label: 'Benin' },
  BFA: { label: 'Burkina Faso' },
  BGD: { label: 'Bangladesh' },
  BGR: { label: 'Bulgaria' },
  BHS: { label: 'Bahamas' },
  BIH: { label: 'Bosnia and Herzegovina' },
  BLR: { label: 'Belarus' },
  BLZ: { label: 'Belize' },
  BOL: { label: 'Bolivia' },
  BRA: { label: 'Brazil' },
  BRN: { label: 'Brunei' },
  BTN: { label: 'Bhutan' },
  BWA: { label: 'Botswana' },
  CAF: { label: 'Central African Republic' },
  CAN: { label: 'Canada' },
  CHE: { label: 'Switzerland' },
  CHL: { label: 'Chile' },
  CHN: { label: 'China' },
  CIV: { label: "Cote d'Ivoire" },
  CMR: { label: 'Cameroon' },
  COD: { label: 'Democratic Republic of Congo' },
  COG: { label: 'Congo' },
  COL: { label: 'Colombia' },
  CRI: { label: 'Costa Rica' },
  CUB: { label: 'Cuba' },
  CYP: { label: 'Cyprus' },
  CZE: { label: 'Czechia' },
  DEU: { label: 'Germany' },
  DJI: { label: 'Djibouti' },
  DNK: { label: 'Denmark' },
  GRL: { label: 'Greenland' },
  DOM: { label: 'Dominican Republic' },
  DZA: { label: 'Algeria' },
  ECU: { label: 'Ecuador' },
  EGY: { label: 'Egypt' },
  ERI: { label: 'Eritrea' },
  ESP: { label: 'Spain' },
  EST: { label: 'Estonia' },
  ETH: { label: 'Ethiopia' },
  FIN: { label: 'Finland' },
  FJI: { label: 'Fiji' },
  FRA: { label: 'France' },
  GUF: { label: 'French Guiana' },
  GAB: { label: 'Gabon' },
  GBR: { label: 'United Kingdom' },
  GEO: { label: 'Georgia' },
  GHA: { label: 'Ghana' },
  GIN: { label: 'Guinea' },
  GMB: { label: 'Gambia' },
  GNB: { label: 'Guinea-Bissau' },
  GNQ: { label: 'Equatorial Guinea' },
  GRC: { label: 'Greece' },
  GTM: { label: 'Guatemala' },
  GUY: { label: 'Guyana' },
  HND: { label: 'Honduras' },
  HRV: { label: 'Croatia' },
  HTI: { label: 'Haiti' },
  HUN: { label: 'Hungary' },
  IDN: { label: 'Indonesia' },
  IND: { label: 'India' },
  IRL: { label: 'Ireland' },
  IRN: { label: 'Iran' },
  IRQ: { label: 'Iraq' },
  ISL: { label: 'Iceland' },
  ISR: { label: 'Israel' },
  ITA: { label: 'Italy' },
  JAM: { label: 'Jamaica' },
  JOR: { label: 'Jordan' },
  JPN: { label: 'Japan' },
  KAZ: { label: 'Kazakhstan' },
  KEN: { label: 'Kenya' },
  KGZ: { label: 'Kyrgyzstan' },
  KHM: { label: 'Cambodia' },
  KOR: { label: 'South Korea' },
  XXK: { label: 'Kosovo' },
  KWT: { label: 'Kuwait' },
  LAO: { label: 'Laos' },
  LBN: { label: 'Lebanon' },
  LBR: { label: 'Liberia' },
  LBY: { label: 'Libya' },
  LKA: { label: 'Sri Lanka' },
  LSO: { label: 'Lesotho' },
  LTU: { label: 'Lithuania' },
  LUX: { label: 'Luxembourg' },
  LVA: { label: 'Latvia' },
  MAR: { label: 'Morocco' },
  MDA: { label: 'Moldova' },
  MDG: { label: 'Madagascar' },
  MEX: { label: 'Mexico' },
  MKD: { label: 'North Macedonia' },
  MLI: { label: 'Mali' },
  MMR: { label: 'Myanmar' },
  MNE: { label: 'Montenegro' },
  MNG: { label: 'Mongolia' },
  MOZ: { label: 'Mozambique' },
  MRT: { label: 'Mauritania' },
  MWI: { label: 'Malawi' },
  MYS: { label: 'Malaysia' },
  NAM: { label: 'Namibia' },
  NCL: { label: 'New Caledonia' },
  NER: { label: 'Niger' },
  NGA: { label: 'Nigeria' },
  NIC: { label: 'Nicaragua' },
  NLD: { label: 'Netherlands' },
  NOR: { label: 'Norway' },
  NPL: { label: 'Nepal' },
  NZL: { label: 'New Zealand' },
  OMN: { label: 'Oman' },
  PAK: { label: 'Pakistan' },
  PAN: { label: 'Panama' },
  PER: { label: 'Peru' },
  PHL: { label: 'Philippines' },
  PNG: { label: 'Papua New Guinea' },
  POL: { label: 'Poland' },
  PRI: { label: 'Puerto Rico' },
  PRK: { label: 'North Korea' },
  PRT: { label: 'Portugal' },
  PRY: { label: 'Paraguay' },
  QAT: { label: 'Qatar' },
  ROU: { label: 'Romania' },
  USA: { label: 'United States of America' },
};
