import {
  AccessTime,
  AttachMoney,
  BarChart,
  BrandingWatermark,
  Calculate,
  CalendarToday,
  Cloud,
  ColorLens,
  DateRange,
  EventNote,
  Fingerprint,
  Grade,
  Label,
  Language,
  Link,
  List,
  Map,
  MyLocation,
  Numbers,
  Palette,
  People,
  Percent,
  QuestionMark,
  Radar,
  StackedLineChart,
  TextFields,
  Timer,
  ToggleOn,
  Visibility,
} from '@mui/icons-material';

import { UiType } from '.';

export interface UiTypeMetadata {
  type: ColumnType;
  icon: React.ComponentType;
  description: string;
  title: string;
  expectedProperties: string[];
}

export const type = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
  timestamp: 'timestamp',
  date: 'date',
  array: 'array',
  keyValue: 'keyValue',
  presence: 'presence',
  position: 'position',
  timeRange: 'timeRange',
} as const;

export const typeExamples = {
  string: 'a text like "Sample text"',
  number: 'a number like 10',
  boolean: 'a boolean like true',
  timestamp: 'a timestamp like 2021-07-01T12:00:00Z',
  date: 'a date like 2021-07-01',
  array: 'a simple array like ["item1", "item2"]',
  keyValue: 'a key value array like [{ "key": <key>, "value": <value> }]',
  presence:
    'an array of presence like [{ "appearances": [{ "timeRange": { "start": 0, "end": 10 }, "label": <label>, "image": <url> }]]',
  position:
    'an array of positions like [{ "x": 0, "y": 0, "width": 100, "height": 100 }]',
  timeRange: 'a time range like { "start": 0, "end": 10 }',
} as const;

export type ColumnType = (typeof type)[keyof typeof type];

const typeProperties: Record<ColumnType, string[]> = {
  [type.string]: ['value'],
  [type.number]: ['value'],
  [type.boolean]: ['value'],
  [type.timestamp]: ['value'],
  [type.date]: ['value'],
  [type.array]: ['value'],
  [type.keyValue]: ['key', 'value'],
  [type.presence]: ['appearances', 'label', 'image', 'imageID'],
  [type.position]: ['x', 'y', 'width', 'height'],
  [type.timeRange]: ['start', 'end'],
};

export const uiTypeProperties: Record<UiType, UiTypeMetadata> = {
  text: {
    type: type.string,
    icon: TextFields,
    description: 'Represents plain text input',
    title: 'Text Input',
    expectedProperties: typeProperties[type.string],
  },
  people: {
    type: type.string,
    icon: People,
    description: "Represents a person's name",
    title: 'Person Name',
    expectedProperties: typeProperties[type.string],
  },
  color: {
    type: type.string,
    icon: Palette,
    description: 'Represents a color value',
    title: 'Color',
    expectedProperties: typeProperties[type.string],
  },
  timestamp: {
    type: type.timestamp,
    icon: AccessTime,
    description: 'Represents a point in time',
    title: 'Timestamp',
    expectedProperties: typeProperties[type.timestamp],
  },
  url: {
    type: type.keyValue,
    icon: Link,
    description: 'Represents a web link',
    title: 'URL',
    expectedProperties: typeProperties[type.keyValue],
  },
  language: {
    type: type.string,
    icon: Language,
    description: 'Represents a language code',
    title: 'Language',
    expectedProperties: typeProperties[type.string],
  },
  tag: {
    type: type.string,
    icon: Label,
    description: 'Represents a tag or label',
    title: 'Tag',
    expectedProperties: typeProperties[type.string],
  },
  uuid: {
    type: type.string,
    icon: Fingerprint,
    description: 'Represents a unique identifier',
    title: 'UUID',
    expectedProperties: typeProperties[type.string],
  },
  brand: {
    type: type.string,
    icon: BrandingWatermark,
    description: 'Represents a brand or company',
    title: 'Brand',
    expectedProperties: typeProperties[type.string],
  },
  id: {
    type: type.string,
    icon: Numbers,
    description: 'Represents a numeric identifier',
    title: 'ID',
    expectedProperties: typeProperties[type.string],
  },
  confidence: {
    type: type.number,
    icon: Grade,
    description: 'Represents a confidence score',
    title: 'Confidence Score',
    expectedProperties: typeProperties[type.number],
  },
  duration: {
    type: type.number,
    icon: Timer,
    description: 'Represents a time duration',
    title: 'Duration',
    expectedProperties: typeProperties[type.number],
  },
  currency: {
    type: type.number,
    icon: AttachMoney,
    description: 'Represents a monetary value',
    title: 'Currency',
    expectedProperties: typeProperties[type.number],
  },
  percentage: {
    type: type.number,
    icon: Percent,
    description: 'Represents a percentage value',
    title: 'Percentage',
    expectedProperties: typeProperties[type.number],
  },
  count: {
    type: type.number,
    icon: Calculate,
    description: 'Represents a numeric count',
    title: 'Count',
    expectedProperties: typeProperties[type.number],
  },
  boolean: {
    type: type.boolean,
    icon: ToggleOn,
    description: 'Represents a true/false value',
    title: 'Boolean',
    expectedProperties: typeProperties[type.boolean],
  },
  date: {
    type: type.date,
    icon: CalendarToday,
    description: 'Represents a calendar date',
    title: 'Date',
    expectedProperties: typeProperties[type.date],
  },
  simpleList: {
    type: type.array,
    icon: List,
    description: 'Represents a simple list of items',
    title: 'Simple List',
    expectedProperties: typeProperties[type.array],
  },
  colorPalette: {
    type: type.keyValue,
    icon: ColorLens,
    description: 'Represents a palette of colors',
    title: 'Color Palette',
    expectedProperties: typeProperties[type.keyValue],
  },
  presences: {
    type: type.presence,
    icon: Visibility,
    description: 'Represents presence information',
    title: 'Presences',
    expectedProperties: typeProperties[type.presence],
  },
  tagCloud: {
    type: type.keyValue,
    icon: Cloud,
    description: 'Represents a cloud of tags',
    title: 'Tag Cloud',
    expectedProperties: typeProperties[type.keyValue],
  },
  stackedChart: {
    type: type.keyValue,
    icon: StackedLineChart,
    description: 'Represents data for a stacked chart',
    title: 'Stacked Chart',
    expectedProperties: typeProperties[type.keyValue],
  },
  presenceSummary: {
    type: type.presence,
    icon: Visibility,
    description: 'Represents a summary of presences',
    title: 'Presence Summary',
    expectedProperties: typeProperties[type.presence],
  },
  positionMatrix: {
    type: type.position,
    icon: BarChart,
    description: 'Position Matrix',
    title: 'Position Matrix',
    expectedProperties: typeProperties[type.position],
  },
  barChart: {
    type: type.keyValue,
    icon: BarChart,
    description: 'Represents data for a bar chart',
    title: 'Bar Chart',
    expectedProperties: typeProperties[type.keyValue],
  },
  worldMapChart: {
    type: type.keyValue,
    icon: Map,
    description: 'Represents data for a bar chart',
    title: 'World Map',
    expectedProperties: typeProperties[type.keyValue],
  },
  spiderChart: {
    type: type.keyValue,
    icon: Radar,
    description: 'Represents data for a bar chart',
    title: 'Radar Chart',
    expectedProperties: typeProperties[type.keyValue],
  },
  tagsPresence: {
    type: type.presence,
    icon: Label,
    description: 'Represents presence of tags',
    title: 'Tags Presence',
    expectedProperties: typeProperties[type.presence],
  },
  calendar: {
    type: type.array,
    icon: EventNote,
    description: 'Represents calendar data',
    title: 'Calendar',
    expectedProperties: typeProperties[type.array],
  },
  unknown: {
    type: type.string,
    icon: QuestionMark,
    description: 'Represents an unknown type',
    title: 'Unknown',
    expectedProperties: [],
  },
  position: {
    type: type.position,
    icon: MyLocation,
    description: 'Represents a position and size',
    title: 'Position',
    expectedProperties: typeProperties[type.position],
  },
  timeRange: {
    type: type.timeRange,
    icon: DateRange,
    description: 'Represents a range of time',
    title: 'Time Range',
    expectedProperties: typeProperties[type.timeRange],
  },
};
